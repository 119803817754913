import { useEffect, useRef } from 'react';

export const useScrollToAnchor = () => {
  const scrolledRef = useRef(false)
  const hash = window.location.hash

  useEffect(() => {
    if (scrolledRef.current || !hash) {
      return
    }
    if (hash) {
      const element = document.getElementById(hash.slice(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        scrolledRef.current = true
      }
    }
  })
}

